export default [
  {
    key: 'media_id',
    image: 'image_url',
    label: 'Foto Profil',
    type: 'image',
    notes: [
      'Harap unggah foto profil dengan <b>rasio 1:1</b>.',
      'Misalnya, 500 x 500 px, 1000 x 1000 px, dll.',
    ],
  },
  {
    key: 'name',
    label: 'Nama',
  },
  {
    key: 'username',
    label: 'Username',
  },
  {
    key: 'email',
    label: 'Email',
    type: 'email',
  },
  {
    key: 'phone',
    label: 'Nomor HP',
  },
]
